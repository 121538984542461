// extracted by mini-css-extract-plugin
export var container = "ProfileDeactivateSubscription-module--container--1SlPU";
export var content = "ProfileDeactivateSubscription-module--content--2xddE";
export var title = "ProfileDeactivateSubscription-module--title--3sQyx";
export var goBackIcon = "ProfileDeactivateSubscription-module--goBackIcon--2kH70";
export var box = "ProfileDeactivateSubscription-module--box--53dyZ";
export var whyLeaving = "ProfileDeactivateSubscription-module--whyLeaving--1TGeg";
export var description = "ProfileDeactivateSubscription-module--description--2W7vA";
export var card = "ProfileDeactivateSubscription-module--card--3Gvn9";
export var cardImageQuestion = "ProfileDeactivateSubscription-module--cardImageQuestion--wedH0";
export var cardImageChat = "ProfileDeactivateSubscription-module--cardImageChat--2yIVv";
export var cardImageRefund = "ProfileDeactivateSubscription-module--cardImageRefund--2qakf";
export var cardDescription = "ProfileDeactivateSubscription-module--cardDescription--2q_jn";
export var cardTitle = "ProfileDeactivateSubscription-module--cardTitle--1wZ6g";
export var cardLink = "ProfileDeactivateSubscription-module--cardLink--1b4SC";
export var button = "ProfileDeactivateSubscription-module--button--1yQhI";
export var footerContainer = "ProfileDeactivateSubscription-module--footerContainer--7PZaG";