import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { trackingGA } from '@merchx-v2/helpers'
import { navigate } from 'utils/navigate'
import { Button, Footer, Header, Notification, Select, SEO } from "components";

import chatIcon from "assets/chatIcon.svg";
import goBackIcon from "assets/goBack.svg";
import questionsIcon from "assets/questions.svg";
import refundIcon from "assets/refund.svg";

import {
  actionCreators as actionHomeCreators,
  selectMenu,
} from "state/HomePage/reducer";
import {
  actionCreators as loginActions,
  selectors,
} from "state/LoginPage/reducer";
import * as actions from "state/LoginPage/actions";
import * as styles from "./ProfileDeactivateSubscription.module.scss";

const reasonOptions = [
  "Poor customer support",
  "Not enough products",
  "Shipping issues",
  "Membership cost",
  "Other",
];

const ProfileDeactivateSubscription = ({
  loginState,
  menu,

  customerAccountDeactivateSubscription,
  resetMenuFilters,
  setMenuCollection,
  setSubscriptionDeclineReason,
}) => {
  const [notificationTitle, setNotificationTitle] = useState("Error");
  const [notification, showNotification] = useState();
  const [isShowErrors, setIsShowErrors] = useState(false);

  // const [selectedReason, setSelectedReason] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    trackingGA()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCollectionClicked = (collection) => {
    setMenuCollection(collection);
  };

  const handleReset = () => {
    resetMenuFilters();
  };

  const handleConfirmDeactivation = async (e) => {
    e.preventDefault();
    setIsShowErrors(true);

    const isChanged = await customerAccountDeactivateSubscription();
    if (isChanged.payload) {
      navigate("/profile/confirm-deactivation");
    } else {
      setNotificationTitle("Error");
      showNotification(isChanged.error.message);
    }
  };

  if (typeof localStorage === 'undefined' || typeof sessionStorage === 'undefined') {
    return null
  }

  const refreshToken =
    localStorage.getItem("refreshToken") ||
    sessionStorage.getItem("refreshToken");

  if (!refreshToken) {
    return null;
  }

  return (
    <div className={styles.container}>
      <SEO pathname='/profile/deactivate-subscription' />
      <Header
        menu={menu}
        onCollectionClicked={handleCollectionClicked}
        onResetCollection={handleReset}
      />
      <div className={styles.content}>
        <div className={styles.title}>
          <img
            className={styles.goBackIcon}
            src={goBackIcon}
            alt="<"
            onClick={() => navigate('/profile')}
          />
          We're sorry to see you go!
        </div>

        <div className={styles.box}>
          <div>
            <p className={styles.whyLeaving}>Why are you leaving?</p>
            <Select
              placeholder="Please select one"
              onSelect={setSubscriptionDeclineReason}
              isValid={true}
              isShowErrors={isShowErrors}
              errorMessage="Please select your Reason"
              value={loginState.subscriptionDeclineReason}
              options={reasonOptions}
            />
          </div>

          <p className={styles.description}>
            We hope you've enjoyed the free t-shirts and crazy discounts. Just
            so you know, we plan on releasing tons of cool new features soon.
            Don't worry you can always reactivate your VIP membership if you
            change your mind!
          </p>

          <div className={styles.card}>
            <img
              className={styles.cardImageQuestion}
              src={questionsIcon}
              alt=""
            />
            <div className={styles.cardDescription}>
              <p className={styles.cardTitle}>Have questions about our shop?</p>
              <p
                className={styles.cardLink}
                onClick={() => navigate("/faq")}
              >
                Click here to get answers from FAQ’s
              </p>
            </div>
          </div>

          <div className={styles.card}>
            <img className={styles.cardImageChat} src={chatIcon} alt="" />
            <div className={styles.cardDescription}>
              <p className={styles.cardTitle}>Need to reach out to us?</p>
              <p
                className={styles.cardLink}
                onClick={() => navigate("/support")}
              >
                Click here to contact us
              </p>
            </div>
          </div>

          <div className={styles.card}>
            <img className={styles.cardImageRefund} src={refundIcon} alt="" />
            <div className={styles.cardDescription}>
              <p className={styles.cardTitle}>Not completely satisfied?</p>
              <p
                className={styles.cardLink}
                onClick={() => navigate("/refund-policy")}
              >
                Click here to see refund policy
              </p>
            </div>
          </div>

          <div className={styles.button}>
            <Button
              value="Confirm Deactivation"
              className="buttonSmall"
              customAction={handleConfirmDeactivation}
              loading={loginState.UIState.isLoading}
            />
          </div>
        </div>
      </div>
      <div className={styles.footerContainer}>
        <Footer handleReset={handleReset} />
        <Notification
          title={notificationTitle}
          message={notification}
          onNotificationClosed={() => {
            setNotificationTitle("Error");
            showNotification("");
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  menu: selectMenu(state),
  loginState: selectors.selectLoginState(state),
});

const mapDispatchToProps = {
  customerAccountDeactivateSubscription:
    actions.customerAccountDeactivateSubscription,
  resetMenuFilters: actionHomeCreators.resetMenuFilters,
  setMenuCollection: actionHomeCreators.setMenuCollection,
  setSubscriptionDeclineReason: loginActions.setSubscriptionDeclineReason,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileDeactivateSubscription);
